import React from 'react'
import PropTypes from 'prop-types'
import { Text } from 'components/atoms/Typography'

const ErrorLabel = ({ error, ...props }) => (
  <Text color="error" {...props} fontSize="xs">
    {error}
  </Text>
)

ErrorLabel.defaultProps = {
  error: '',
}

ErrorLabel.propTypes = {
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}

export default ErrorLabel
