import Alert from 'react-s-alert'
import UAParser from 'ua-parser-js'
import { ERROR_MESSAGE } from 'consts'
import request from './request'

const errorHelper = async ({
  error,
  componentName,
  functionName,
  errorMessage = ERROR_MESSAGE,
  errorType = 'web',
  timeout = 5000,
  showAlert = true,
  sendError = true,
}) => {
  try {
    const parser = new UAParser()
    const result = parser.getResult()

    const { browser, cpu, os } = result || {}
    const { name: browserName = '', version: browserVersion } = browser || {}
    const { architecture = '' } = cpu || {}
    const { name: osName = '', version: osVersion = '' } = os || {}

    const system_str = `${osName} ${osVersion}, ${browserName} ${browserVersion}, ${architecture}`

    console.error(`An error occurred in ${componentName}, ${functionName} \n ${system_str} \n ${error}`)

    if (showAlert) {
      Alert.error(errorMessage || ERROR_MESSAGE, { timeout })
    }

    if (sendError) {
      await request({
        method: 'POST',
        path: 'error',
        body: {
          type: errorType,
          error: `${componentName}, ${functionName} \n ${system_str} \n ${JSON.stringify(
            error,
            Object.getOwnPropertyNames(error)
          )}`,
        },
      })
    }
  } catch (error) {
    console.error('An error occurred when sending an error from web', error.stack)
  }
}

export default errorHelper
